<template>
  <div class="add-pro-wrapper">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="修改代理"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-col>
    </el-row>

    <div class="add-pro-content">
      <el-row :gutter="15">
      <!-- 左模块 -->
        <el-col :span="18">
          <!-- 基本信息 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="4">
                <div class="part-info">
                  <div class="title">基本</div>
                  <div class="info">代理信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="20">
                <el-row :gutter="20">
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.uuid" :disabled="true">
                        <template slot="prepend">uuid</template>
                      </el-input>
                  </el-col>
                  <el-col :span="12">
                      <el-input placeholder="请输入内容" v-model="form.username">
                        <template slot="prepend">用户名</template>
                      </el-input>
                  </el-col>
                </el-row>
                <el-row :gutter="20" class="mt10">
                  <el-col :span="24">
                      <el-input placeholder="请输入内容" v-model="form.password">
                        <template slot="prepend">密码</template>
                      </el-input>
                  </el-col>
                </el-row>

                <el-input
                  type="textarea"
                  style="margin-top:10px"
                  :autosize="{ minRows: 4, maxRows: 6}"
                  placeholder="请输入内容"
                  v-model="form.comment">
                </el-input>
              </el-col>
            </el-row>
          </el-card>
          <!-- 价格库存 -->
          <el-card class="box-card">
            <el-row :gutter="20">
              <!-- 左边 -->
              <el-col :span="3">
                <div class="part-info">
                  <div class="title">套餐</div>
                  <div class="info">套餐信息</div>
                </div>
              </el-col>
              <!-- 右边 -->
              <el-col :span="21">
                <el-button type="primary" icon="el-icon-plus" plain @click="dialogVisible1=true">添加代理可使用的套餐</el-button>
                <el-table
                  :data="agent_codetype_list"
                  style="width: 100%">

                  <el-table-column type="expand">
                    <template slot-scope="props">
                      <el-descriptions>
                          <el-descriptions-item label="套餐创建时间">{{props.row.create_time_str}}</el-descriptions-item>
                          <el-descriptions-item label="套餐备注">{{props.row.comment}}</el-descriptions-item>
                      </el-descriptions>
                    </template>
                  </el-table-column>

                  <el-table-column
                    prop="name"
                    label="套餐名"
                    >
                  </el-table-column>
                  <el-table-column
                    prop="time_limit"
                    label="激活时限"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="platform"
                    label="平台"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="allow_device_num"
                    label="允许设备数"
                    width="50">
                  </el-table-column>
                  <el-table-column
                    prop="allow_create_code_num"
                    label="激活码数"
                    width="80">
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-edit"
                        circle
                        @click="handleEdit(scope.$index, scope.row)"></el-button>
                      <el-button
                        size="mini"
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        @click="handleDelete(scope.$index, scope.row)"></el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-col>
            </el-row>
          </el-card>
        </el-col>

        <!-- 右模块 -->
        <el-col :span="6">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>状态</span>
            </div>
            <p class="p-extra mb5">启用状态</p>
            <el-select v-model="form.status" placeholder="请选择账号状态" style="width:100%" class="mb10">
              <el-option
                v-for="item in status_arr"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- 添加套餐类型 -->
    <el-dialog
      :append-to-body="true"
      title="选择可使用的套餐"
      :visible.sync="dialogVisible1"
      width="30%">

        <el-form ref="dialogVisible1" :model="addAgentCodeTypeForm" label-width="80px">
          <el-form-item label="套餐">
            <el-select v-model="addAgentCodeTypeForm.id_arr" multiple placeholder="请选择">
                <el-option
                v-for="item in code_type"
                :key="item.id"
                :label="item.name"
                :value="item.id">
                </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addDone1">确认添加</el-button>
      </span>
    </el-dialog>

    <!-- 修改套餐类型 -->
    <el-dialog
      :append-to-body="true"
      :title="'修改套餐 '+editAgentCodeTypeForm.name"
      :visible.sync="dialogVisible2"
      width="30%">

        <el-form ref="dialogVisible2" :model="editAgentCodeTypeForm" label-width="100px">
          <el-form-item label="修改类型">
            <el-radio-group v-model="editAgentCodeTypeForm.update_type">
              <el-radio label="add">增加</el-radio>
              <el-radio label="reduce">减少</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="激活码数量">
              <el-input-number v-model="editAgentCodeTypeForm.num" :min="0" :max="9999" clearable></el-input-number>
          </el-form-item>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="done2">确认修改</el-button>
      </span>
    </el-dialog>




  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
export default {
  name:'addProducts',
    components:{
      Title
    },
    data(){
      return{
        dialogVisible1:false,
        dialogVisible2:false,
        form:{},
        status_arr:[
          {name:'启用/激活',value:1},
          {name:'禁用/未激活',value:0},
        ],
        agent_codetype_list:[],//代理的套餐列表:[],
        addAgentCodeTypeForm:{},
        editAgentCodeTypeForm:{num:1,update_type:'add'},
        code_type:[],
      }
    },
    methods:{
			confirm(ob,callback){
				this.$confirm(ob.msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: !ob.type?'warning':ob.type
				}).then(() => {
					callback()
				}).catch((e) => {
					console.error(e)
					this.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			},
      save(){
        console.log(this.form)
        apis.agent.update(this.form).then(res=>{
          console.log('res',res)
          this.$router.push({name:'agentsList'})
        }).catch(err=>{
          this.$message({msg:err,type:'error'})
          console.log('err',err)
        })
      },
      readOne(){
        let p = this.$route.params.obj || {uuid:this.$route.query.uuid};
        if(!p.uuid) return;
        console.log(p)
        apis.agent.readOne({uuid:p.uuid}).then(res=>{
          console.log('res',res)
          // this.form_loading = false;
          this.form = res.data.data
        }).catch(err=>{
          this.form_loading = false;
          console.log('err',err)
        })
      },
      ReadOneAgentCodeType(){
        let p = this.$route.params.obj || {uuid:this.$route.query.uuid};
        if(!p.uuid) return;
        apis.code_type.readOneAgentCodeType({agent_uuid:p.uuid}).then(res=>{
          console.log('res',res)
          this.agent_codetype_list = res.data.data
        }).catch(err=>{
          this.form_loading = false;
          console.log('err',err)
        })
      },
      addDone1(){
        this.addAgentCodeTypeForm.agent_uuid = this.form.uuid;
        apis.code_type.addAgentCodeType(this.addAgentCodeTypeForm).then(res=>{
          this.$message({message:res.data.msg,type:'success'})
          this.ReadOneAgentCodeType();
          this.dialogVisible1 = false
        }).catch(err=>{
          this.$message({message:err,type:'error'})
          this.dialogVisible1 = false
          console.log('err',err)
        })
      },
      //选择修改代理套餐
      handleEdit(index,row){
        this.dialogVisible2=true;
        Object.assign(this.editAgentCodeTypeForm,{
          name:row.name,
          num:1,
          update_type:'add',
          agents_code_type_id:row.id
        })
      },
      //选择删除代理套餐
      handleDelete(index,row){
        this.confirm({msg:'确认删除该套餐'},()=>{
            const param = {
              agents_code_type_id:row.id
            }
            apis.code_type.removeAgentCodeType(param).then(res=>{
              this.$message({message:res.data.msg,type:'success'})
              this.ReadOneAgentCodeType();
            }).catch(err=>{
              this.$message({message:err,type:'error'})
              console.log('err',err)
            })
        })
      },
      //确认修改代理套餐
      done2(){
        apis.code_type.updateAgentCodeType(this.editAgentCodeTypeForm).then(res=>{
          this.$message({message:res.data.msg,type:'success'})
          this.dialogVisible2=false;
          this.ReadOneAgentCodeType();
        }).catch(err=>{
          this.$message({message:err,type:'error'})
          this.dialogVisible2=false;
          console.log('err',err)
        })
      },
      getCodeTypeList(){
        let op = {
          page_num:1,
          page_size:100
        }
        apis.code_type.read(op).then(res=>{
          this.code_type = (res.data.data.list || [])
        }).catch(err=>{
          console.log('err',err)
        })
      },
    },
    mounted(){
      this.readOne();
      this.getCodeTypeList();
      this.ReadOneAgentCodeType();
    },
    created(){
    },
    computed:{

    },
    watch:{
    }
}
</script>

<style lang="scss">
.add-pro-wrapper{
  .add-pro-content{
    box-sizing: border-box;
    width: 100%;
    padding:15px;
  }
  .box-card{
    margin-bottom:5px;
  }
  .part-info{
    // @include flex_ccc();
    // background: brown;
    .title{
      font-size: 18px;
      color:#333;
    }
    .info{
      font-size: 12px;
      color:#999;
    }
  }
}
</style>